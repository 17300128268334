$(function() {
    (function($) {
        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
    
        $.fn.attrchange = function(callback) {
            if (MutationObserver) {
                var options = {
                    subtree: false,
                    attributes: true
                };
    
                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(e) {
                        callback.call(e.target, e.attributeName);
                    });
                });

                return this.each(function() {
                    observer.observe(this, options);
                });
            }
        }
    })(jQuery);

    //Now you need to append event listener
    $('.modal').attrchange(function(attrName) {
        $that = this

        if (attrName == 'class') {
            if ($that.classList.contains('show')) {
                var event = new Event('modal.on', {bubbles: true});
                $that.dispatchEvent(event);
            }
            else {
                var event = new Event('modal.off', {bubbles: true});
                $that.dispatchEvent(event);
            }
        }
    });
});