(function ($) {
    /**
     * Product slider
     **/
    var $form = $('#js-filter-form'),
        $content = $('#js-products_content')


    let isTriggered = false
    let isProcessed = false
    let canContinue = true

    $(document).on('submit', '#js-filter-form', function (e) {
        e.preventDefault()

        $content.addClass('is-loaded');
        $('html, body').animate({
            scrollTop: $content.offset().top - 100
        }, 500);
        $('body').find('[name="page"]').val(1)
        var data = compress($form.serialize());

        $.ajax({
            type: 'post',
            url: ajax.url,
            data: data,
            success: function (response) {
                response = JSON.parse(response);

                // console.log(response);

                $content.html(response.content)
                var regex = /strona\/\b\d+\b\//,
                    base_url = window.location.href.replace(regex, '').split('?')[0]
                history.pushState(null, '', base_url + '?' + response.query);
                $content.removeClass('is-loaded')

                if (response.total_label) {
                    $('body').find('#js-products-loop-counter').text(response.total_label)
                }

                // $('.pagination a').each(function () {
                //     var href = $(this).attr('href')
                //     $(this).attr('href', href + '?' + response.query)
                // });

                $('.js-custom-select').select2({
                    minimumResultsForSearch: -1,
                    width: 'resolve'
                });
                canContinue = response.continue
            },
            error: function (response) {
                $content.removeClass('is-loaded')
            }
        });
    })

    function compress(data) {
        data = data.replace(/([^&=]+=)([^&]*)(.*?)&\1([^&]*)/g, "$1$2,$4$3")
        return /([^&=]+=).*?&\1/.test(data) ? compress(data) : data
    }

    /**
     * ilość wpisów na stronie
     */
    $(document).on('change', '#js-results-count', function (e) {
        e.preventDefault();
        var params = insertParam('per_page', $(this).val()),
            regex = /strona\/\b\d+\b\//,
            base_url = window.location.href.replace(regex, '').split('?')[0]
        window.history.pushState('', '', base_url)
        document.location.search = params;
    })

    /**
     * ilość wpisów na stronie
     */
    $(document).on('change', '#js-order-change', function (e) {
        e.preventDefault();
        var val = $(this).val(),
            order = $(this).find(':selected').attr('data-order'),
            regex = /strona\/\b\d+\b\//,
            base_url = window.location.href.replace(regex, '').split('?')[0]

        var params = insertParam('order_by', val)

        if (order) {
            window.history.pushState('', '', base_url + '?' + params)
            var params = insertParam('order', order)
            document.location.search = params;
        } else {
            document.location.search = params;
        }
    })

    //scroll event
    const trigger = document.getElementById('js-products-loop-trigger')
    const $loader = $('#js-products-loop-loader')
    if(trigger){
    document.addEventListener('scroll', function () {
        if (!isTriggered) {
            isTriggered = isInViewport(trigger) ? true : false;
        }
        else {
            if (!isProcessed && canContinue) {
                isProcessed = true
                $loader.addClass('is-loaded');
                let pager = $('body').find('[name="page"]'),
                    pager_val = parseInt(pager.val())
                pager.val(pager_val + 1)
                var data = compress($form.serialize());
                $.ajax({
                    type: 'post',
                    url: ajax.url,
                    data: data,
                    success: function (response) {
                        response = JSON.parse(response);
                        // console.log(response);

                        $content.append(response.content)
                        isTriggered = false
                        isProcessed = false
                        canContinue = response.continue
                        $loader.removeClass('is-loaded')
                    },
                    error: function (response) {
                        isTriggered = false
                        isProcessed = false
                        canContinue = false
                        $loader.removeClass('is-loaded')
                    }
                });
            }
        }
    }, { passive: true });
    }

    function insertParam(key, value) {
        key = encodeURIComponent(key);
        value = encodeURIComponent(value);

        // kvp looks like ['key1=value1', 'key2=value2', ...]
        var kvp = document.location.search.substr(1).split('&');
        var i = 0;

        for (; i < kvp.length; i++) {
            if (kvp[i].startsWith(key + '=')) {
                var pair = kvp[i].split('=');
                pair[1] = value;
                kvp[i] = pair.join('=');
                break;
            }
        }

        if (i >= kvp.length) {
            kvp[kvp.length] = [key, value].join('=');
        }

        // can return this or...
        var params = kvp.join('&');

        return params;
    }

    function isInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

})(jQuery);