(function ($) {

    // Background position
    $(function () {
        $(window).resize(function () {
            clearTimeout(window.resizedFinished);
            window.resizedFinished = setTimeout(function () {
                setBackgroundPosition();
                setSliderPosition();
                setSocialPosition();
            }, 250);
        });

        function setBackgroundPosition() {
            var sectionImgWrapp = $('.section__img-wrap'),
                section = $('.home-about');

                if (sectionImgWrapp.length) {
                    var sectionOffsetTop = sectionImgWrapp.offset().top,
                        sectionOffsetLeft = section.offset().left;
                
                    sectionImgWrapp.append('<style>.home-about::before{top:' + sectionOffsetTop + 'px;left:' + sectionOffsetLeft + 'px;}</style>');
                }
            
        }
        setBackgroundPosition();

        function setSliderPosition() { 
            if ($('.home-contact .container').length) {
                var eLeft = $('.home-contact .container').offset().left;
            }

            // Offer slider
            $('.offer-slider').offset({ left: eLeft - 5 });
            $('.home-offer #home-offer-prev').mouseover(function () {
                $(this).offset({ left: eLeft - 25 });
            })
            .mouseout(function () {
                $(this).offset({ left: 0 });
            });
            
            $('.home-offer .offer-slider').mouseover(function () {
                $('#home-offer-prev').offset({ left: eLeft - 25 });
            })
            .mouseout(function () {
                $('#home-offer-prev').offset({ left: 0 });
            });
            
            // Instagram slider
            $('.instagram-slider').offset({ left: eLeft - 5 });
            $('.home-social #home-instagram-prev').mouseover(function () {
                $(this).offset({ left: eLeft - 25 });
            })
            .mouseout(function () {
                $(this).offset({ left: 0 });
            });
            
            $('.home-social .instagram-slider').mouseover(function () {
                $('#home-instagram-prev').offset({ left: eLeft - 25 });
            })
            .mouseout(function () {
                $('#home-instagram-prev').offset({ left: 0 });
            });

            $('.slick-dots').offset({ left: eLeft + 10 });
            $('.col-left').css('padding-left', eLeft - 15 + 'px');
            $('.col-right').css('padding-right', eLeft - 15 + 'px');
        }
        setSliderPosition();

        function setSocialPosition() {

            var width = $(window).innerWidth();

            $('.social').offset({ left: width - 48 });
        }
        setSocialPosition();
    });
    
})(jQuery);