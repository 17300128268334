(function ($) {

    /**
     * Product slider
     **/
    var $slider = $('#js-products-list-slider'),
        $next = $('#js-products-list-next')
    
    /**
     * Init slick
     **/
    $slider.slick({
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 4000,
        speed: 300,
        rows: 0,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });

    /**
     * Arorws
     **/
    $next.on('click', function() {
        $slider.slick('slickNext');
    });
    
})(jQuery);
				
