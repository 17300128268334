$(document).ready(function () {

    $('.shop-single-navigation__list-link[href*="#"]').bind('click', function (e) {
        
        e.preventDefault();
        var target = $(this).attr("href");

        $('html, body').stop().animate({
            scrollTop: $(target).offset().top - 100
        }, 600);

        return false;
    });
});