(function ($) {

    /**
     * Offer slider
     **/
    var $slider = $('#js-home-offer-slider'),
        $prev = $('#js-home-offer-prev'),
        $next = $('#js-home-offer-next'),
        $dots = $('#js-home-offer-dots');

    /**
     * Init slick
     **/
    $slider.slick({
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 4000,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1340,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    appendDots: $dots,
                }
            },
        ]
    });

    /**
     * Arorws
     **/
    $next.on('click', function() {
        $slider.slick('slickNext');
    });

    $prev.on('click', function() {
        $slider.slick('slickPrev');
    });
    
})(jQuery);