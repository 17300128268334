$(function () {

    /**
     * Fixed header
     **/

    var header = $('.header'),
        scrollClass = 'header--scroll',
        modalHeader = $('.modal__dialog'),
        scrollClassModal = 'modal__dialog--scroll',
        hiddenClass = 'header--hidden';
    
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;

    var headerOuterHeight = $('header').outerHeight();

    $(document).ready(function () {
        setMenuScrolled();
    });

    $(window).scroll(function (event) {
        if (!header.hasClass('site-header--open')) {
            didScroll = true;
            setMenuScrolled();
        }
    });

    setInterval(function () {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        var st = $(this).scrollTop();

        if (Math.abs(lastScrollTop - st) <= delta) return;

        if (st > lastScrollTop && st > headerOuterHeight) {
            header.addClass(hiddenClass);
        } else {
            if (st + $(window).height() < $(document).height()) {
                header.removeClass(hiddenClass);
            }
        }

        lastScrollTop = st;
    }

    function setMenuScrolled() {
        if ($(this).scrollTop() > 0 && !header.hasClass(scrollClass)) {
            header.addClass(scrollClass);
            modalHeader.addClass(scrollClassModal);
        } else if ($(this).scrollTop() == 0 && header.hasClass(scrollClass)) {
            header.removeClass(scrollClass);
            modalHeader.removeClass(scrollClassModal);
        }
    }

    /**
     * Responsive menu
     */

    var burger = $('#burgerIcon');

    burger.click(function () {
        $(this).toggleClass('burger-icon--open');

        if (header.hasClass('header--open')) {
            header.addClass('header--closed');
            $('body').removeClass('no-scroll');
            setTimeout(function () {
                header.removeClass('header--closed').removeClass('header--open');
            }, 250);

        } else {
            header.addClass('header--opened');
            $('body').addClass('no-scroll');
            setTimeout(function () {
                header.removeClass('header--opened').addClass('header--open');
            }, 250);
        }
    });
});