(function ($) {
    var $filterTrigger = $('.js-search-filter')
    var $filterList = $('#js-filter-list')
    var $searchResults = $('#js-search-results')

    $filterTrigger.on('click', function (e) {
        e.preventDefault()
        var $that = $(this)

        //set active
        $filterTrigger.removeClass('is-active')
        $that.addClass('is-active')
        
        //searched
        var searched = $filterList.attr('data-searched')
        var type = $that.attr('data-type')

        //ajax function
        $searchResults.addClass('is-loading');
        _ajaxLoad(type, searched, 1)
    })

    /**
     * pagination
     */
    var searchPagination = '.js-search-pagination'
    var $searchResultsList = $('#js-results')

    $(document).on('click', searchPagination, function(e) {
        e.preventDefault()
        var searched = $filterList.attr('data-searched')
        var post_type = $filterList.find('.is-active').attr('data-type')
        var page = $(this).attr('data-target')

        //ajax function
        $('html, body').animate({scrollTop: $searchResultsList.offset().top - 200}, 300)
        $searchResults.addClass('is-loading')
        _ajaxLoad(post_type, searched, page)
    })

    var searchProductPagination = '#js-search-product-pagination'

    $(document).on('click', searchProductPagination + ' a', function(e) {
        e.preventDefault()
        var searched = $filterList.attr('data-searched')
        var post_type = 'products'
        var page = $(this).attr('data-target')

        //ajax function
        $('html, body').animate({scrollTop: $searchResultsList.offset().top - 200}, 300)
        $searchResults.addClass('is-loading')
        _ajaxLoad(post_type, searched, page)
    })
    
    /**
     *  ajax
     */
    function _ajaxLoad(type, s, paged) {
        $.ajax({
            url:ajax.url,
            data: {
                action: 'load_search_results',
                post_type: type,
                paged: paged,
                s: s,
            },
            type:'post',
            success:function(data){
                $searchResults.html(data).removeClass('is-loading')
            }
        });
        return false
    }

})(jQuery);