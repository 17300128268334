(function ($) {

    /**
     * Mega menu
     **/
    var megamenu_trigger = '.js-megamenu',
        menu = '#js-main-header'

    $('.js-menu-item').hover(function(e) {
        hide_megamenu()
        var $that = $(this)
        
        if ($that.hasClass('js-megamenu')) {
            if ($that.hasClass('js-megamenu-collections')) {
                show_megamenu('#js-megamenu-collections')
            }
            else if ($that.hasClass('js-megamenu-contact')) {
                show_megamenu('#js-megamenu-contact')
            }
        }
        else {
            hide_megamenu()
        }
    });
    
    $(menu).mouseleave(function() {
        hide_megamenu()
    })
    
    $('.js-hide-megamenu').hover(function() {
        hide_megamenu()
    })

    function show_megamenu(target) {
        $(menu).addClass('is-megamenu')
        $(target).addClass('is-active')
    }

    function hide_megamenu() {
        $(menu).removeClass('is-megamenu')
        $('.js-megamenu-content').removeClass('is-active')
    }
    
})(jQuery);