(function ($) {

    /**
     * Product slider
     **/
    var $slider = $('#js-single-product-slider'),
        $thumbnails = $('#js-single-thumbnail-slider'),
        $prev = $('#js-single-product-prev'),
        $next = $('#js-single-product-next'),
        $thumb = $('.js-single-product-thumb')

    /**
     * Init slick
     **/
    $slider.slick({
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1
    })

    $slider.on('afterChange', function(event, slick, currentSlide){
        $thumb.removeClass('is-active')
        $('[data-target="' + currentSlide + '"]').addClass('is-active')
    })

    /**
     * Arorws
     **/
    $next.on('click', function() {
        $slider.slick('slickNext')
    })

    $prev.on('click', function() {
        $slider.slick('slickPrev')
    })

    $thumb.on('click', function() {
        $slider.slick('goTo', $(this).attr('data-target'))
    })


    /**
     * Zmiana URL po kliknięciu pin
     **/
    $('.product-listing-color__pin').on('click', function() {

        var url = $(this).data('url');
        console.log(url);
        var productId = $(this).data('id');
        $(`[data-product-id=${productId}]`).attr('href', url);
    });
    
})(jQuery);
				
