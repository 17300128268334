(function ($) {
    var $filterTrigger = $('.js-files-filter')
    var $filterList = $('#js-filter-list')
    var $searchResults = $('#js-files-results')

    $filterTrigger.on('click', function (e) {
        e.preventDefault()
        var $that = $(this)

        //set active
        $filterTrigger.removeClass('is-active')
        $that.addClass('is-active')
        
        //searched
        var searched = $filterList.attr('data-searched')
        var type = $that.attr('data-type')

        //ajax function
        $searchResults.addClass('is-loading');
        _ajaxLoad(type, searched, 1)
    })

    /**
     * pagination
     */
    var $searchResultsList = $('#js-results')
    var searchProductPagination = '#js-search-files-pagination'

    $(document).on('click', searchProductPagination + ' a', function(e) {
        e.preventDefault()
        var searched = $filterList.attr('data-searched')
        var tax = $filterList.find('.is-active').attr('data-type')
        var page = $(this).attr('data-target')

        //ajax function
        $('html, body').animate({scrollTop: $searchResultsList.offset().top - 200}, 300)
        $searchResults.addClass('is-loading')
        _ajaxLoad(tax, searched, page)
    })
    
    /**
     *  ajax
     */
    function _ajaxLoad(tax, s, paged) {
        $.ajax({
            url:ajax.url,
            data: {
                action: 'load_files_search_results',
                tax: tax,
                paged: paged,
                s: s,
            },
            type:'post',
            success:function(data){
                $searchResults.html(data).removeClass('is-loading')
            }
        });
        return false
    }

})(jQuery);