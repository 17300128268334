(function ($) {

    /**
     * Landing page slider(s)
     **/
    var $sliders = $('[id^=js-landingpage-slider]');

    /**
     * Init Slick Slider for each slider
     */
    $.each($sliders, (index, slider) => {

        var $id = $(slider).attr('data-id');
            $prev = $(`#js-landingpage-slider-prev-${ $id }`),
            $next = $(`#js-landingpage-slider-next-${ $id }`),
            $dots = $(`#js-landingpage-slider-dots-${ $id }`);

        /**
         * Init slick
         **/
        $(slider).slick({
            dots: true,
            arrows: false,
            infinite: true,
            autoplaySpeed: 4000,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            appendDots: $dots,
        });

        /**
         * Arrows
         **/
        $next.on('click', function () {
            $(slider).slick('slickNext');
        });

        $prev.on('click', function () {
            $(slider).slick('slickPrev');
        });
    })

})(jQuery);