(function ($) {
    /**
     * scroll to fix - inicjacja wtyczki
     */
    var padding = 110;

    if ($(window).width() < 1380) {
        padding = 80;
    }

    $('.js-smooth-scroll').click(function(event) {
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname
        ) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                var fromTop = target.offset().top;
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: fromTop - padding
                }, 500, function() {
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                } else {
                    $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                };
            });
        }
    }
});
})(jQuery);