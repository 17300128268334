(function ($) {

    /**
     * Product slider
     **/
    var $slider = $('#js-search-products-slider'),
        $prev = $('#js-search-products-prev'),
        $next = $('#js-search-products-next'),
        $dots = $('#js-search-products-dots'),
        $filterTrigger = $('.js-category-filter'),
        $filterSelect = $('#js-search-slider-select')


    $(document).on('focus', '#s', function() {
        $(this).parents('form').addClass('is-focused')
    })

    $(document).on('blur', '#s', function() {
        if ($(this).val() == '') {
            $(this).parents('form').removeClass('is-focused')
        }
    })

    elem = document.getElementById('js-search-modal');
    elem?.addEventListener('modal.on', function(event) {
        $slider.addClass('is-loaded')
        
        setTimeout(function() {
            initSlick($slider)
            $slider.removeClass('is-loaded')
        }, 500)
    });
    
    /**
     * Init slick
     **/
    function initSlick($slider) {     
        $slider.not('.slick-initialized').slick({
            dots: false,
            arrows: false,
            infinite: true,
            autoplay: false,
            autoplaySpeed: 4000,
            speed: 300,
            rows: 0,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1340,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: true,
                        appendDots: $dots,
                        arrows: false
                    }
                },
                {
                    breakpoint: 460,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    }

    /**
     * Arorws
     **/
    $next.on('click', function() {
        $slider.slick('slickNext');
    });

    $prev.on('click', function() {
        $slider.slick('slickPrev');
    });

    $filterTrigger.on('click', function(e) {
        e.preventDefault()
        var $that = $(this),
            cat = $that.attr('data-cat'),
            target = $that.attr('data-target')
            
        //set active
        $filterTrigger.removeClass('is-active')
        $that.addClass('is-active')

        filterSlider(cat, target)
    })

    $filterSelect.on('change', function(e) {
        e.preventDefault()
        var $that = $(this),
        cat = $that.val(),
        target = $that.attr('data-target')
        
        filterSlider(cat, target)
    })

    function filterSlider(cat, target) {
        //filter slides
        $(target).addClass('is-loaded')        

        if (cat == 0) {
            $(target).slick('slickUnfilter')
            setTimeout(function() {
                $(target).removeClass('is-loaded')
            }, 500)
        }
        else {
            $(target).slick('slickUnfilter')
            $(target).slick('slickFilter', $('[data-cat-item="' + cat + '"]'))
            setTimeout(function() {
                $(target).removeClass('is-loaded')
            }, 500)
        }
    }
    
})(jQuery);
				
