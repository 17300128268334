(function ($) {
        
    // Product gallery

    var gallery = $('#product-gallery'), //gallery
        galleryNav = '.js-product-gallery-nav', // gallery nav
        prev = $('#single-product-prev'),
        next = $('#single-product-next');

    // Slick
    gallery.slick({
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        slidesToScroll: 1
    });

    // gallery nav
    $(galleryNav).on('click', function() {
        gallery.slick('slickGoTo', $(this).attr('data-target'))
    })

    gallery.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $(galleryNav).each(function(){
            $(this).removeClass('active-slide')
        })
        $(galleryNav + '[data-target="' + nextSlide + '"]').addClass('active-slide')
    });

    // init simpleLightBox
    if( $('.gallery__item').length ) {
        var lightbox = $('.gallery__item').not('.slick-cloned').simpleLightbox({
            showCounter: false,
            docClose: false,
            nav: true,
            navText: ['<i class="icon icon-arrow"></i>','<i class="icon icon-arrow"></i>']
        });
    }

    $('.gallery__zoom').on('click', function() {
        lightbox.open();
    })

    /**
     * Arorws
     **/
    next.on('click', function() {
        gallery.slick('slickNext');
    });

    prev.on('click', function() {
        gallery.slick('slickPrev');
    });

})(jQuery);