(function ($) {

    /**
     * Offer slider
     **/
    var $pinTrigger = $('.js-pin-popup-trigger'),
        pinWindowClass = 'js-pin-popup-window'

    /**
     * trigger event
     */
    $pinTrigger.on('click', function() {
        var $that = $(this),
            topPos = $that.offset().top,
            leftPos = $that.offset().left,
            directionTop = $that.attr('data-top'), 
            directionLeft = $that.attr('data-left'), 
            productId = $that.attr('data-product-id') 

        if (!$that.hasClass('is-active')) {
            $pinTrigger.each(function() {
                $(this).removeClass('is-active')
            })

            $that.addClass('is-active')
            createPopup(topPos, leftPos, directionTop, directionLeft, productId)
        }
    })

    /**
     * click outside close
     */
    $(document).click(function(event) { 
        var $target = $(event.target),
            popupClass = '.' + pinWindowClass

        if (!$target.hasClass(pinWindowClass) && !$target.parents(popupClass).length > 0) {
            if ($(popupClass).length > 0 && $(popupClass).hasClass('is-visible')) {
                removePopup()
                $pinTrigger.removeClass('is-active')
            }
        }        
    });
    
    /**
     * create and append popup
     * @param {int} top 
     * @param {int} left 
     */
    function createPopup(top, left, direction_top, direction_left, product_id) {
        removePopup()
        var $popup = document.createElement('div')
        
        $($popup).addClass('pin-popup ' + pinWindowClass + ' pin-popup--' + direction_top + '-' + direction_left).css({
            'top' : top + 'px',
            'left' : left + 'px'
        })
        
        var $popupOverlay = document.createElement('div')
        $($popupOverlay).addClass('pin-popup-overlay')

        $('body').append($popup)
        $('body').append($popupOverlay)

        ajaxLoadPopup($($popup), product_id)

        setTimeout(function() {
            $($popup).addClass('is-visible')
            $($popupOverlay).addClass('is-visible')
        }, 200)
    }

    /**
     * remove popup
     */
    function removePopup() {
        var $popup = '.' + pinWindowClass

        if ($popup.length > 0) {
            $($popup).remove()
            $('.pin-popup-overlay').remove()
        }
    }

    function ajaxLoadPopup(popup, product_id) {        
        if (popup.length > 0) {
            popup.addClass('is-loaded')

            $.ajax({
                url:ajax.url,
                data: {
                    action: 'load_product_tooltip',
                    product_id: product_id
                },
                type:'post',
                success:function(data){
                    popup.html(data)
                    popup.removeClass('is-loaded')
                }
            })
            return false;
        }
    }
    
})(jQuery);