(function ($) {
    /**
     * where to buy autofocus
     */
    $(document).ready(function() {
        $input = $('#js-where-to-buy')

        if ($input.length > 0) {
            $input.focus()
        }

    })

})(jQuery);