(function ($) {
    /**
     * Create GA events listeners
     */
    let doubleClick = false;
    $(".js-ga-catalog-click").on("click", function () {
        if (!doubleClick) {
            _protectDoubleClick();

            console.log("Not double click.");
            let eventCategory = "catalog";
            let eventName = "Download button click";

            //you should first check if ga is set
            if (typeof ga !== "undefined") {
                ga("send", "event", eventCategory, eventName);
            }
            //check if _gaq is set too
            if (typeof _gaq !== "undefined") {
                _gaq.push(["_trackEvent", eventCategory, eventName]);
            }
        }
    });

    /**
     * Protect from double events on the same element
     * @param {boolean} doubleClick - Reference to variable with doubleclick status
     */
    function _protectDoubleClick() {
        doubleClick = true;

        window.setTimeout(function () {
            doubleClick = false;
        }, 10000);
    }
})(jQuery);
