(function ($){
    //scroll event
    const trigger = document.getElementById('js-products-search-trigger');
    const $loader = $('#js-products-search-loader');
    if(trigger){

        let isTriggered = false;
        let isProcessed = false;
        let canContinue = true;

        const $content = $('#js-search-results .row');
    
        var $form = $('#js-search-products');

    document.addEventListener('scroll', function () {
        if (!isTriggered) {
            isTriggered = isInScroll(trigger) ? true : false;
        }else {
            if (!isProcessed && canContinue) {
                isProcessed = true;
                $loader.addClass('is-loaded');
                let pager = $('body').find('[name="page"]'),
                    pager_val = parseInt(pager.val());
                pager.val(pager_val + 1);
                var data = compress($form.serialize());
                $.ajax({
                    type: 'post',
                    url: ajax.url,
                    data: data,
                    success: function (response) {
                        response = JSON.parse(response);
                        $content.append(response.content);
                        isTriggered = false;
                        isProcessed = false;
                        canContinue = response.continue;
                        $loader.removeClass('is-loaded');

                        /**
                         * Zmiana URL po kliknięciu pin
                         **/
                        $('.product-listing-color__pin').on('click', function() {

                            var url = $(this).data('url');
                            console.log(url);
                            var productId = $(this).data('id');
                            $(`[data-product-id=${productId}]`).attr('href', url);
                        });
                    },
                    error: function (response) {
                        isTriggered = false;
                        isProcessed = false;
                        canContinue = false;
                        $loader.removeClass('is-loaded');
                    }
                });
            }
        }
    });
    }

    function isInScroll(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    function compress(data) {
        data = data.replace(/([^&=]+=)([^&]*)(.*?)&\1([^&]*)/g, "$1$2,$4$3")
        return /([^&=]+=).*?&\1/.test(data) ? compress(data) : data
    }

})(jQuery);