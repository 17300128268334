(function ($) {
$(document).ready(function () {

    $('body').on('click', '.product-listing-color__pin', function(){
        let $that = $(this),
            $parent = $that.parents('.product-listing-big-box'), 
            colorIndex = $that.data('color-number'),
            targetElement = $parent.find('.product-listing-box__img-container [data-number="'+colorIndex+'"]');
        targetElement.addClass('active').siblings().removeClass('active');
    });

});
})(jQuery);