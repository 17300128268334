// Specific for B2B registration scripts
(function ($) {
    // Create dependency required distribution field
    const $fromDistrField = $("[id^=client_type]");
    const $fromDistrNameField = $("#from_distr_name");

    // Fade distributor name field
    $fromDistrNameField.fadeOut("fast");

    $fromDistrField.on("change", function () {
        if ($(this).is("#client_type_2:checked")) {
            $fromDistrNameField.attr("required", true);
            $fromDistrNameField.fadeIn("fast");
        } else {
            $fromDistrNameField.attr("required", false);
            $fromDistrNameField.val("");
            $fromDistrNameField.fadeOut("fast");
        }
    });

    // Any required script
    // const $anyRequired = $(".js-any-required");
    // const $anyRequiredInputs = $anyRequired.find(".js-any-required-input");

    // $anyRequiredInputs.each(function () {
    //     $(this).on("change", function () {
    //         console.log(anyRequiredInputsDefaultVal.first().el);

    //         if ($anyRequiredInputs.is(":checked")) {
    //             $anyRequiredInputs.attr("required", false);
    //         } else {
    //             anyRequiredInputsDefaultVal.each(function () {
    //                 console.log($(this));
    //                 // $(this).el[0].attr("required", $(this).required);
    //             });
    //         }
    //     });
    // });
})(jQuery);
