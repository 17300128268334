(function ($) {
    var map,
        mapContainer = document.getElementById('contactMap')

    if (mapContainer) {
        var lat = parseFloat(mapContainer.dataset.lat),
            lng = parseFloat(mapContainer.dataset.lng),
            zoom = parseInt(mapContainer.dataset.zoom)
        
        map = new google.maps.Map(mapContainer, {
            minZoom: 5,
            maxZoom: 18,
            zoom: zoom,
            center: {lat: lat, lng: lng},
            scrollwheel: false,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
            },
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            // styles: [{
            //         "featureType": "all",
            //         "elementType": "labels.text.fill",
            //         "stylers": [{
            //                 "saturation": 36
            //             },
            //             {
            //                 "color": "#333333"
            //             },
            //             {
            //                 "lightness": 40
            //             }
            //         ]
            //     },
            //     {
            //         "featureType": "all",
            //         "elementType": "labels.text.stroke",
            //         "stylers": [{
            //                 "visibility": "on"
            //             },
            //             {
            //                 "color": "#ffffff"
            //             },
            //             {
            //                 "lightness": 16
            //             }
            //         ]
            //     },
            //     {
            //         "featureType": "all",
            //         "elementType": "labels.icon",
            //         "stylers": [{
            //             "visibility": "off"
            //         }]
            //     },
            //     {
            //         "featureType": "administrative",
            //         "elementType": "geometry.fill",
            //         "stylers": [{
            //                 "color": "#f5f5fb"
            //             },
            //             {
            //                 "lightness": 20
            //             },
            //             {
            //                 "visibility": "off"
            //             }
            //         ]
            //     },
            //     {
            //         "featureType": "administrative",
            //         "elementType": "geometry.stroke",
            //         "stylers": [{
            //                 "color": "#f5f5fb"
            //             },
            //             {
            //                 "lightness": 17
            //             },
            //             {
            //                 "weight": 1.2
            //             }
            //         ]
            //     },
            //     {
            //         "featureType": "landscape",
            //         "elementType": "geometry",
            //         "stylers": [{
            //                 "color": "#f5f5fb"
            //             },
            //             {
            //                 "lightness": 20
            //             }
            //         ]
            //     },
            //     {
            //         "featureType": "poi",
            //         "elementType": "geometry",
            //         "stylers": [{
            //                 "color": "#dedee7"
            //             },
            //             {
            //                 "lightness": 21
            //             }
            //         ]
            //     },
            //     {
            //         "featureType": "poi.park",
            //         "elementType": "geometry",
            //         "stylers": [{
            //                 "color": "#dedee7"
            //             },
            //             {
            //                 "lightness": 21
            //             }
            //         ]
            //     },
            //     {
            //         "featureType": "road.highway",
            //         "elementType": "geometry.fill",
            //         "stylers": [{
            //                 "color": "#ffffff"
            //             },
            //             {
            //                 "lightness": 17
            //             }
            //         ]
            //     },
            //     {
            //         "featureType": "road.highway",
            //         "elementType": "geometry.stroke",
            //         "stylers": [{
            //                 "color": "#ffffff"
            //             },
            //             {
            //                 "lightness": 29
            //             },
            //             {
            //                 "weight": 0.2
            //             }
            //         ]
            //     },
            //     {
            //         "featureType": "road.arterial",
            //         "elementType": "geometry",
            //         "stylers": [{
            //                 "color": "#ffffff"
            //             },
            //             {
            //                 "lightness": 18
            //             }
            //         ]
            //     },
            //     {
            //         "featureType": "road.local",
            //         "elementType": "geometry",
            //         "stylers": [{
            //                 "color": "#ffffff"
            //             },
            //             {
            //                 "lightness": 16
            //             }
            //         ]
            //     },
            //     {
            //         "featureType": "transit",
            //         "elementType": "geometry",
            //         "stylers": [{
            //                 "color": "#f2f2f2"
            //             },
            //             {
            //                 "lightness": 19
            //             }
            //         ]
            //     },
            //     {
            //         "featureType": "water",
            //         "elementType": "geometry",
            //         "stylers": [{
            //                 "color": "#aedcfd"
            //             },
            //             {
            //                 "lightness": 17
            //             }
            //         ]
            //     }
            // ]
        });

        markerType = tpl_url + '/includes/where-to-buy/assets/map-pin.png';

        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            icon: markerType,
            map: map
        });
    }
    
})(jQuery);