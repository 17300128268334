(function ($) {

    // Popover
    var point = $('.arrangements__point'),
        popover = $('.arrangements__point-product__item'),
        id = null;

    point.on('mouseover', function () {
        id = $(this).attr('id');
        position = $(this).position();

        popover.each(function () {
            if ($(this).data('popover') === id) {
                $(this).addClass('visible').css('left', position.left - 235).css('top', position.top + 40);
            } else { 
                $(this).removeClass('visible').css('left', 'initial').css('top', 'initial');
            }
        });
    });

    point.on('mouseout', function () {
        popover.each(function () {
            $(this).removeClass('visible').css('left', 'initial').css('top', 'initial');
        });
    });

    popover.hover(
        function () {
            $(this).addClass('visible').css('left', position.left - 235).css('top', position.top + 40);
        },
        function () {
            $(this).removeClass('visible').css('left', 'initial').css('top', 'initial');
        }
    );

})(jQuery);