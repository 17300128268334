(function ($) {

    /**
     * Offer slider
     **/
    var slider = $('.arrangements-slider'),
        prev = $('#product-arrangements-prev'),
        next = $('#product-arrangements-next');

    /**
     * Init slick
     **/
    slider.slick({
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 4000,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    /**
     * Arorws
     **/
    next.on('click', function() {
        slider.slick('slickNext');
    });

    prev.on('click', function() {
        slider.slick('slickPrev');
    });
    
})(jQuery);