(function($) {
    /**
     * Region select
     **/
    var $regionSelect = $(".js-region-select"),
        regionItem = ".js-region-item",
        $regionMapItem = $(".js-map-region");

    $regionMapItem.click(function() {
        const regionId = $(this).attr("data-region");
        setActiveRegion(regionId);
        const regionValue = $regionSelect
            .find(`[data-region-id=${regionId}]`)
            .val();
        $regionSelect.val(regionValue).trigger("change");
    });

    $regionSelect.change(function() {
        const selected = $(this).find(":selected");
        const regionId = selected.data("regionId");
        setActiveRegion(regionId);
    });

    function setActiveRegion(region) {
        $(regionItem).removeClass("is-active");
        $('[data-region="' + region + '"]').addClass("is-active");
    }
})(jQuery);
