(function ($) {

    /**
     * Home banner
     **/
    var $slider = $('#js-home-banner-slider'),
        $prev = $('#js-home-banner-prev'),
        $next = $('#js-home-banner-next'),
        $dots = $('#js-home-banner-dots');

    /**
     * Init slick
     **/
    $slider.slick({
        dots: true,
        arrows: false,
        infinite: true,
        autoplaySpeed: 4000,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: $dots,
    });  

    /**
     * Arrows
     **/
    $next.on('click', function() {
        $slider.slick('slickNext');
    });

    $prev.on('click', function() {
        $slider.slick('slickPrev');
    });
    
})(jQuery);