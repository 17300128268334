(function ($) {

    /**
     * Product slider
     **/
    var $slider = $('.js-products-slider'),
        $prev = $('.js-products-prev'),
        $next = $('.js-products-next')
    
    /**
     * Init slick
     **/
    $slider.each(function() {
        $(this).slick({
            dots: false,
            arrows: true,
            prevArrow: '<button class="slider__arrow slider__arrow--prev slider-arrow products-slider__arrow"><i class="icon icon-arrow-prev"></i></button>',
            nextArrow: '<button class="slider__arrow slider__arrow--next slider-arrow products-slider__arrow"><i class="icon icon-arrow-next"></i></button>',
            appendArrows: $(this).attr('data-arrow-target'),
            infinite: true,
            autoplay: false,
            autoplaySpeed: 4000,
            speed: 300,
            rows: 0,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1340,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: false,
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    })

    /**
     * Arorws
     **/
    $next.on('click', function() {
        var target = $(this).attr('data-target')
        $(target).slick('slickNext');
    });

    $prev.on('click', function() {
        var target = $(this).attr('data-target')
        $(target).slick('slickPrev');
    });
    
})(jQuery);
				
