(function ($) {

    // jQuery test
    // if (window.jQuery) {
        // console.log('jQuery is working...');
    // }

    // // ajax variables test
    // console.log("ajax.url:", ajax.url);
    // console.log("ajax.nonce:", ajax.nonce);

    // Toggle block    
    $('#toggle').html('Więcej informacji');
    $('#toggle').on('click', function(e){
    e.preventDefault();
        
    var $this = $(this),
        content = $('.content--toggle');  
    
    if(!$this.hasClass('trigger')){
        $this.addClass('trigger');
        $this.html('zwiń');
        content.addClass('show');
        // content.slideDown();
    } else {
        $this.removeClass('trigger');
        $this.html('Więcej informacji');
        content.removeClass('show');
        // content.slideUp();
    }
    });


})(jQuery);