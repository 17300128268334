(function ($) {
    /**
     * Form checkbox toggler
     */
    const $checkboxToggler = $(".js-toggle-all-checkboxes");
    const $checkboxSelectText = $(".js-toggle-all-checkboxes-select");
    const $checkboxUnselectText = $(".js-toggle-all-checkboxes-unselect");

    $checkboxToggler.on("click", null, function (event) {
        const $checkboxContainer = $(this).closest("fieldset");
        const $checkboxes = $checkboxContainer.find('input[type="checkbox"]');

        // Set checkboxes to selected
        $checkboxes.prop("checked", $(this).val() === "true" ? true : false);

        // Toggle button value
        $(this).val($(this).val() === "true" ? false : true);

        // Toggle button text
        $checkboxSelectText.toggleClass("d-none");
        $checkboxUnselectText.toggleClass("d-none");
    });

    /**
     * Form elememt duplicator
     */
    $(document).on("click", ".js-form-el-duplicator", function (e) {
        e.preventDefault();

        const duplicatorElSelector = $(e.target).data("duplicator-el");
        const $duplicatorEl = $(duplicatorElSelector).last();
        const $duplicatorElClone = $duplicatorEl.clone();

        // Clear all fields
        $duplicatorElClone.find("input").val("").prop("checked", false);

        // Iterate all inputs, labels necessary attributes
        $duplicatorElClone.find("input").each(function () {
            iterateAttrs($(this), ["id", "name"]);
        });

        $duplicatorElClone.find("label").each(function () {
            iterateAttrs($(this), ["for"]);
        });

        $duplicatorElClone
            .find("[class*=js-show-el], [class*=js-hide-el]")
            .each(function () {
                iterateAttrs($(this), ["class"]);
            });

        // Append new element to parent
        $duplicatorElClone.appendTo($duplicatorEl.parent()).hide().slideDown();
    });

    /**
     * Hide on checked
     */

    $(".js-form-toggle-on-change").each(function () {
        const showElSelector = $(this).data("show-el");
        $(showElSelector).find("input").hide();
        $(showElSelector).hide();
    });

    $(document).on("change", ".js-form-toggle-on-change", function (e) {
        const isChecked = $(this).is(":checked");
        const hideElSelector = $(this).data("hide-el");
        const showElSelector = $(this).data("show-el");

        toggleRequired($(`${hideElSelector}, ${showElSelector}`));

        if (isChecked) {
            $(hideElSelector).find("input, textarea, select").hide();
            $(showElSelector).find("input, textarea, select").show();

            $(hideElSelector).slideUp();
            $(showElSelector).slideDown();
        } else {
            $(hideElSelector).find("input, textarea, select").show();
            $(showElSelector).find("input, textarea, select").hide();

            $(hideElSelector).slideDown();
            $(showElSelector).slideUp();
        }
    });

    $(document).on("change", ".js-form-toggle-required-on-change", function (
        e
    ) {
        const isChecked = $(this).is(":checked");
        const requireElSelector = $(this).data("require-el");
        const unrequireElSelector = $(this).data("unrequire-el");

        if (isChecked) {
            toggleRequired($(`${unrequireElSelector}`), "unrequire");
            toggleRequired($(`${requireElSelector}`), "require");
        } else {
            toggleRequired($(`${unrequireElSelector}`), "require");
            toggleRequired($(`${requireElSelector}`), "unrequire");
        }
    });

    /**
     * Anonymous form
     */
    $(document).on("change", ".js-form-anonymous", function (e) {
        const isChecked = $(e.target).is(":checked");
        const $anonymousTarget = $(".js-form-anonymous-target");

        // Hide asterixs and falsify required tag if input is checked
        if (isChecked) {
            $anonymousTarget.find("input, textarea").addClass("not-send");
            toggleRequired($anonymousTarget, "unrequire");
        } else {
            $anonymousTarget.find("input, textarea").removeClass("not-send");
            toggleRequired($anonymousTarget, "require");
        }
    });

    function iterateAttrs($el, attrsList = [], iterateData = true) {
        if (iterateData) {
            Object.entries($el[0].dataset).forEach(([name, value]) => {
                const iteratedDataVal = iterateAttrValue(value);
                $el[0].dataset[name] = iteratedDataVal;
            });
        }

        attrsList.forEach((attrName) => {
            const attrValue = $el.attr(attrName);

            if (attrValue) {
                const iteratedIdVal = iterateAttrValue(attrValue);
                $el.attr(attrName, iteratedIdVal);
            }
        });
    }

    function iterateAttrValue(value) {
        const regexp = /(?<prefix>[-_])(?<number>\d*)$|\[(?<arrNumber>\d*)\]$/;
        const matchedIndex = value.match(regexp);

        const { number, prefix, arrNumber } = matchedIndex.groups;

        if (number && prefix) {
            return value.replace(regexp, `${prefix}${parseInt(number) + 1}`);
        } else if (arrNumber) {
            return value.replace(regexp, `[${parseInt(arrNumber) + 1}]`);
        } else {
            return value;
        }
    }

    function toggleRequired($wrappers, action = "toggle") {
        const $isRequired = $wrappers.find("[required]");
        const $wasRequired = $wrappers.find(".was-required");
        const $asterix = $wrappers.find(".js-input-asterix");

        // Show, hide or toggle asterix
        if (action === "require") {
            $asterix.show();
        } else if (action === "unrequire") {
            $asterix.hide();
        } else if (action === "toggle") {
            $asterix.toggle();
        }

        // Make inputs required or not
        if (action === "toggle" || action === "require") {
            $wasRequired.removeClass("was-required").prop("required", true);
        }

        if (action === "toggle" || action === "unrequire") {
            $isRequired.addClass("was-required").prop("required", false);
        }
    }

    /**
     * Remove parent button handle
     */
    $(document).on("click", ".js-form-remove-parent", function () {
        $(this)
            .parent()
            .slideUp(500, function () {
                $(this).remove();
            });
    });
})(jQuery);
