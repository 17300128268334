(function ($) {
    var $awardsLine = $('#js-awards-line'),
        $awardsLineContainer = $('#js-awards-line-container'),
        awardsItem = '.js-awards-item'

    $(window).on('scroll', function() {
        if ($awardsLineContainer.length > 0) {
            var top = $awardsLineContainer.offset().top,
                scrollTop = $(window).scrollTop()

            $awardsLine.css({
                'height' : (scrollTop - top + ($(window).height() / 2)) + 'px'
            })
        }
        $(awardsItem).each(function() {
            if (isScrolledIntoView($(this))) {
                $(this).addClass('is-active')
            }
            else {
                $(this).removeClass('is-active')
            }
        })
    })

    function isScrolledIntoView(elem) {
        var docViewTop = $(window).scrollTop();
        var elemTop = $(elem).offset().top - ($(window).height() / 2);
        return (elemTop <= docViewTop);
    }

})(jQuery);