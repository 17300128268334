(function ($) {
    var $pullLeft = $('.js-pull-left')
    var pullLeftContent = '.js-pull-left-content'
    var $pullRight = $('.js-pull-right')
    var pullRightContent = '.js-pull-right-content'

    $(window).on('load', function () {
        pullContainer();
    })

    $(window).resize(function () {
        pullContainer();
    })

    function pullContainer() {
        var offset = 48
        if (window.innerWidth < 1600) {
            offset = 30
        }

        $pullLeft.each(function () {
            var $that = $(this),
                $parent = $that.parent(),
                $img = $that.find(pullLeftContent),
                offsetLeft = $parent.offset().left - offset;
            
            var changeHeight = ($that.attr('change-parent-height') == 'false') ? false : true;

            // 272 = 1920 - maxContainerWidth - (2 x padding)
            if (offsetLeft > 296) {
                offsetLeft = 296
            }

            $that.css({
                'left': '-' + offsetLeft + 'px'
            })
            if (changeHeight && $img.length > 0) {
                $parent.css({
                    'height': $img.outerHeight()
                })
            }
        })

        $pullRight.each(function () {
            var $that = $(this),
                $parent = $that.parent(),
                $img = $that.find(pullRightContent),
                offsetRight = $(window).width() - ($parent.offset().left + $parent.outerWidth()) - offset
            
            var changeHeight = ($that.attr('change-parent-height') == 'false') ? false : true;

            // 272 = 1920 - maxContainerWidth - (2 x padding)
            if (offsetRight > 272) {
                offsetRight = 272
            }

            $that.css({
                'right': '-' + offsetRight + 'px'
            })
            if (changeHeight && $img.length > 0) {
                $parent.css({
                    'height': $img.outerHeight()
                })
            }
        })
    }

})(jQuery);