// Load jQuery Mask plugin nad apply it on the elements
(function ($) {
    // $(".js-input-mask").each(function () {
    //     $input = $(this);
    //     mask = $(this).data("mask");
    //     console.log($input, mask);
    //     // if (mask) {
    //     //     $input.mask(mask);
    //     // }
    // });
})(jQuery);
