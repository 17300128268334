(function ($) {

    /**
     * Home banner
     **/
    var $slider = $('#js-home-instagram-slider'),
        $arrows = $('#js-home-instagram-slider-arrows')

    $(document).ready(function () {
        if ($slider.length > 0) {
            $slider.addClass('is-loaded')
            create_slider();
        }
    })

    function create_slider() {
        $.ajax({
            url:ajax.url,
            data: {
                action: 'get_instagram_photos'
            },
            type:'post',
            success:function(data){
                var images = JSON.parse(data)
                images.forEach(function(elem) {
                    $slider.append('<div class="home-instagram-slider__slide"><a href="' + elem.permalink + '" target="_blank"><div class="home-instagram-slider__image-container" style="background-image: url(' + elem.media_url + ')"></div></a></div>')
                });
                initSlider()
                $slider.removeClass('is-loaded')
            }
        })
        return false;
    }

    /**
     * Init slick
     **/
    function initSlider() {
        $slider.slick({
            dots: false,
            arrows: true,
            appendArrows: $arrows,
            prevArrow: '<button class="slider__arrow slider__arrow--prev slider-arrow" id="js-home-instagram-prev"><i class="icon icon-arrow-prev"></i></button>',
            nextArrow: '<button class="slider__arrow slider__arrow--next slider-arrow" id="js-home-instagram-next"><i class="icon icon-arrow-next"></i></button>',
            infinite: true,
            autoplay: false,
            autoplaySpeed: 3000,
            speed: 200,
            slidesToScroll: 1,
            slidesToShow: 4,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        })
    }
    
})(jQuery);